import React from 'react'
import Link from 'gatsby-link'
import ProfileHeader from '../components/ProfileHeader'
import TemplateWrapper from '../layouts/index'
import FollowForm from '../components/FollowForm'

export default class IndexPage extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      window.dojoRequire(["mojo/signup-forms/Loader"], function(L) { L.start({"baseUrl":"mc.us20.list-manage.com","uuid":"280e7e13eccf242c262ffbbbf","lid":"ccec375126","uniqueMethods":true}) })
      document.cookie = "MCPopupClosed=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    }, 1000);
  }

  render() {
    return (
      <TemplateWrapper>
        <div style={{maxWidth: '800px', padding: '0px 1.0875rem 1.45rem',}}>
          <ProfileHeader />
          <p>
            I am currently a cofounder at <a href="https://kinto.xyz">Kinto</a>.<br /><br />
            Previously founded <a href="http://www.babylon.finance">Babylon.finance</a>, a DeFi protocol that reached more than $50M in AUM.
             Long ago, I worked at <a href="http://www.ycombinator.com/">Y Combinator</a>, building products and helping founders. Before that, I built apps and games for Moz, Google and <a href="https://www.zynga.com/">Zynga</a>.
             In a previous life, founded <a href="https://angel.co/netgamix">Netgamix</a>, a user generated trivia platform that reached more than 100K MAU.
          </p>
          <p>
            After turning 30, I wrote a book to summarize all the insights learned on the way called <Link
              to="/grip">
              Grip
            </Link>
            . I also coach & mentor startup founders. If you are interested, please email me at
            &nbsp;<a href="mailto:ramon@dapis.io" target="_blank">ramon@dapis.io</a>.
          </p>
          <h2> My Articles, Products & Media </h2>
          <ul>
            <li>
              <a href="https://www.youtube.com/watch?v=xK-EU9Vpt_w&t=291s" target="_blank">
                Babylon Finance Pitch on Coinlist
              </a>
            </li>
            <li>
              <a href="https://podcasts.apple.com/us/podcast/growing-your-portfolio-through-investment-daos-with/id1493896529?i=1000567589542" target="_blank">
                CoinGecko Podcast: Growing your portfolio through investment DAOs
              </a>
            </li>
            <li>
              <a href="https://ramonrecuero.com/grip" target="_blank">
                Grip: 33 Principles for a better life
              </a>
            </li>
            <li>
              <a href="https://blog.ycombinator.com/the-token-effect/" target="_blank">
                The Token Effect
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=Xen6AOu8lko" target="_blank">
                YC Podcast with Andy Bromberg (Coinlist Founder)
              </a>
            </li>
            <li>
              <a href="https://blog.aragon.one/the-future-of-organizations/" target="_blank">
                The Future of Organizations (with Jorge Izquierdo)
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=Sy7qdlUMF_A&list=PLAy4HNUNlzRkFI6dgxtsXxMXjjDXuiOgf&index=32&t=0s" target="_blank">
                ETHDenver Panel: Building infrastructure for adoption
              </a>
            </li>
            <li>
              <a href="https://blog.ycombinator.com/crypto-evolution/" target="_blank">
                Crypto Evolution
              </a>
            </li>
            <li>
              <a href="https://blog.ycombinator.com/building-for-the-blockchain/" target="_blank">
                Building for the Blockchain
              </a>
            </li>
            <li>
              <a href="https://blog.ycombinator.com/the-decentralized-future-series/" target="_blank">
                The Decentralized Future Series
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=_m6X9IzH-Vw&feature=youtu.be" target="_blank">
                Building Dapps People Want (Talk at Dappcon 2019)
              </a>
            </li>
            <li>
              <a href="https://medium.com/coinmonks/eth-onboarding-solution-90607fb81380" target="_blank">
                Gas Station Network: The Ultimate Onboarding Solution
              </a>
            </li>
            <li>
              <a href="https://www.producthunt.com/posts/yc-world" target="_blank">
                YC World
              </a>
            </li>
            <li>
              <a href="https://www.producthunt.com/posts/startup-school-2018" target="_blank">
                YC Startup School 2018
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=_v4Gg4Z6q8Y" target="_blank">
                TV Ad to Promote Computer Science (Spanish)
              </a>
            </li>
          </ul>
          <h2> Core Values </h2>
          <ul>
            <li> It's all about people.</li>
            <li> Remember yourself. There's only here and now. Attention is all we have. Use it wisely.</li>
            <li> We overestimate risk. When in doubt, choose the path that leads to the best story. No regrets.</li>
            <li> Life is a game of opposites. Black and White, sound and silence. Let go of the ilusion that you can
                 control life. Treat life as a dance, as play.</li>
            <li> The fewer things you identify with, the happier you will be. Question your conditioning. Free yourself.</li>
            <li> Don't take anything personally. When someone wrongs you, they just can't help it...</li>
          </ul>
          <h2> Recommended Reads </h2>
          <p />
          <ul>
            <li> On Dialogue - David Bohm</li>
            <li> Sapiens - Harari</li>
            <li> The Lord of the rings - J.R.R. Tolkien</li>
            <li> The Evolving Self - Robert Kegan</li>
            <li> How to Win friends and influence people - Dale Carnegie</li>
            <li> Non Violent Communication - Marshal Rosenberg</li>
            <li> The unbearable lightness of being - Milan Kundera</li>
            <li> In search of the miraculous - P.D. Ouspensky</li>
            <li> High Output Managament - Andy Grove</li>
            <li> Hackers & Painters - Paul Graham</li>
            <li> The War of Art - Steven Pressfield</li>
            <li> The Alchemist - Paulo Coelho</li>
            <li> Creativity Inc. - Ed Catmull</li>
            <li> Charlie's Almanack - Charlie Munger</li>
            <li> The Richest Man in Babylon -  George Cleason</li>
            <li> Antifragile - Nassim Taleb</li>
            <li> Atomic Habits - James Clear</li>
          </ul>
          <h2> Subscribe to my Newsletter </h2>
          <FollowForm />
        </div>
      </TemplateWrapper>
    );
  }

}
